import React from "react"

const ProfileBanner = () => {
  return (
    <div className="profile-banner">
      <div className="container">
        <div className="profile-banner-inner">
          <h4>
            An independent Software Engineer and Product Designer specializes in minimal UI and functional UX design<br />
            <br /> Well my name is{" "}
            <span className="semi-bold"> Raphaël M.</span>, i work with both small and big companies from around the world for the last 5 years to help come up
            with a visual language for their online presence.
            I define integrated strategies, create customized tools and deliver dynamic and personalize solution.
            <br />
            <br />
            I offer a complete solution, If you want me to work for you, email me :
            <span className="semi-bold"> raphael@paaolms.com</span>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default ProfileBanner
